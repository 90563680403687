import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 533px;
  height: 289px;
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DynamicCardSVG = ({ backgroundImage }) => {
  return (
    <Container backgroundImage={backgroundImage}>
      <svg width="533" height="289" viewBox="0 0 533 289" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="filter0_b_79_367" x="-8" y="-8" width="549" height="305" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4"/>
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_79_367"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_79_367" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_79_367" x1="191.64" y1="339.649" x2="157.485" y2="-3.88161" gradientUnits="userSpaceOnUse">
            <stop stopColor="#101010"/>
            <stop offset="0.3" stopColor="#6272C7"/>
            <stop offset="0.7" stopColor="#DE98C4"/>
            <stop offset="1" stopColor="#D7C0CA"/>
          </linearGradient>
          <linearGradient id="paint1_linear_79_367" x1="454.148" y1="-1.45134e-05" x2="266.005" y2="288.678" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DE98C4"/>
            <stop offset="0.15" stopColor="#6272C7"/>
            <stop offset="1" stopColor="white" stopOpacity="0"/>
          </linearGradient>
          <radialGradient id="radialGradient1" cx="0.5" cy="0.5" r="0.5">
            <stop offset="0" stopColor="#fff" stopOpacity="1"/>
            <stop offset="1" stopColor="#fff" stopOpacity="0"/>
          </radialGradient>
          <mask id="mask1">
            <circle cx="0" cy="0" r="150" fill="url(#radialGradient1)">
              <animateMotion dur="4s" repeatCount="indefinite" rotate="auto">
                <mpath xlinkHref="#boxPath1"/>
              </animateMotion>
            </circle>
          </mask>
          <radialGradient id="radialGradient2" cx="0.5" cy="0.5" r="0.5">
            <stop offset="0" stopColor="#fff" stopOpacity="1"/>
            <stop offset="1" stopColor="#fff" stopOpacity="0"/>
          </radialGradient>
          <mask id="mask2">
            <circle cx="0" cy="0" r="150" fill="url(#radialGradient2)">
              <animateMotion dur="4s" repeatCount="indefinite" rotate="auto">
                <mpath xlinkHref="#boxPath2"/>
              </animateMotion>
            </circle>
          </mask>
        </defs>
        <g filter="url(#filter0_b_79_367)">
          <rect x="0.5" y="0.5" width="532" height="288" rx="3.5" stroke="url(#paint0_linear_79_367)" strokeOpacity="0.5"/>
          <rect x="0.5" y="0.5" width="532" height="288" rx="3.5" stroke="url(#paint1_linear_79_367)"/>
        </g>
        <use stroke="#DE98C4" strokeLinecap="round" strokeWidth="3" xlinkHref="#box" mask="url(#mask1)">
          <animate attributeName="stroke-dasharray" from="0,0" to="1500,0" dur="4s" repeatCount="indefinite"
            keyTimes="0; 1" keySplines="0.42,0.42,1,0.42"/>
        </use>
        <use stroke="#6272C7" strokeLinecap="round" strokeWidth="3" xlinkHref="#box" mask="url(#mask2)">
          <animate attributeName="stroke-dasharray" from="0,0" to="1500,0" dur="4s" repeatCount="indefinite"
            keyTimes="0; 1" keySplines="0.42,0,0.58,1"/>
        </use>
        <g id="box">
          <path id="boxPath1" fill="transparent" d="M 0.5 0.5 L 0.5 288.5 L 532.5 288.5 L 532.5 0.48 Z "/>
          <path id="boxPath2" fill="transparent" d="M 532.5 288.5 L 532.5 0.5 L 0.5 0.5 L 0.5 288.5 Z"/>
        </g>
      </svg>
    </Container>
  );
};

export default DynamicCardSVG;

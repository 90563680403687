import MoblieNavigation from './MoblieNavigation';
import Home from './sections/MobileHome';
import Card from './sections/Card';
import RoadMap from './sections/MobileChaiRoadMap';
import Footer from './MobileFooter.js';

const Mobile = () =>{
    return (
        <section>
            <MoblieNavigation />
            <Home />
            <Card />
            <RoadMap />
            <Footer />
        </section>
    )
}

export default Mobile;
import styled, { keyframes } from 'styled-components';

const showTitle = keyframes`
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const hideTitle = keyframes`
    to {
        opacity: 0;
        transform: translateY(100px);
    }
`;

export const DownToUp = styled.div`
    opacity: 0;
    transform: translateY(100px);
    &.animate {
        animation: ${showTitle} 0.6s forwards;
    }
    &.hide {
        animation: ${hideTitle} 0s forwards;
    }
`
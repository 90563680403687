import React from 'react';
import styled from 'styled-components';


const IconContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;

    &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); /* 白色半透明背景 */
        border-radius: 50%; /* 圆角效果 */
    }
`;


const IconDoc = () => (
  <svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44px" height="44px">
    <rect x="0" y="0" width="44" height="44" rx="22" fill="#FFE3FF" fillOpacity="0.2"/>
    <path d="M24.3065 14.1427C24.3914 14.1429 24.4751 14.1631 24.5508 14.2016L24.5744 14.2145C24.5989 14.2289 24.6224 14.245 24.6447 14.2627L24.6537 14.2702L24.6783 14.2927L28.264 17.8784L28.2751 17.8902L28.2779 17.8934L28.2565 17.8709L28.2861 17.903L28.2979 17.9173C28.3091 17.9313 28.3196 17.9459 28.3293 17.9609L28.3418 17.982L28.3547 18.0052C28.3926 18.0787 28.4136 18.1616 28.4136 18.2498V29.1426C28.4136 29.332 28.3384 29.5137 28.2044 29.6477C28.0705 29.7816 27.8888 29.8569 27.6994 29.8569H16.2709C16.0814 29.8569 15.8997 29.7816 15.7658 29.6477C15.6318 29.5137 15.5566 29.332 15.5566 29.1426V14.857C15.5566 14.6675 15.6318 14.4859 15.7658 14.3519C15.8997 14.218 16.0814 14.1427 16.2709 14.1427H24.3065ZM23.0565 15.2141H16.628V28.7855H27.3422V19.4998H23.7708C23.5814 19.4998 23.3997 19.4246 23.2657 19.2906C23.1318 19.1566 23.0565 18.975 23.0565 18.7855V15.2141ZM22.5208 24.4998C22.6629 24.4998 22.7992 24.5562 22.8996 24.6567C23.0001 24.7571 23.0565 24.8934 23.0565 25.0355C23.0565 25.1776 23.0001 25.3138 22.8996 25.4143C22.7992 25.5148 22.6629 25.5712 22.5208 25.5712H19.6637C19.5216 25.5712 19.3854 25.5148 19.2849 25.4143C19.1844 25.3138 19.128 25.1776 19.128 25.0355C19.128 24.8934 19.1844 24.7571 19.2849 24.6567C19.3854 24.5562 19.5216 24.4998 19.6637 24.4998H22.5208ZM24.3662 21.2855C24.629 21.2855 24.8422 21.5255 24.8422 21.8212C24.8422 22.1169 24.629 22.3569 24.3662 22.3569H19.6041C19.3412 22.3569 19.128 22.1169 19.128 21.8212C19.128 21.5255 19.3412 21.2855 19.6041 21.2855H24.3662ZM24.1279 15.2573V18.4284H27.299L24.1279 15.2573Z" fill="white" stroke="white" strokeWidth="0.392854"/>
  </svg>
);


const HoverableIconDoc = () => (
  <IconContainer>
      <IconDoc />
  </IconContainer>
);

export default HoverableIconDoc;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vh;
  width: 346.4px;
  height: 222px; 
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const DynamicCardSVG = ({ backgroundImage,display }) => {
  const svgRef = React.useRef(null);
  return (
    <Container backgroundImage={backgroundImage} >
      <svg  width="346" height="222" viewBox="0 0 346 222" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="filter0_b_79_367" x="-8" y="-7.5" width="549" height="237" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_79_367" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_79_367" result="shape" />
          </filter>
          <linearGradient id="paint0_linear_79_367" x1="191.64" y1="260.232" x2="171.586" y2="-3.54038" gradientUnits="userSpaceOnUse">
            <stop stopColor="#101010" />
            <stop offset="0.3" stopColor="#6272C7" />
            <stop offset="0.7" stopColor="#DE98C4" />
            <stop offset="1" stopColor="#D7C0CA" />
          </linearGradient>
          <linearGradient id="paint1_linear_79_367" x1="454.148" y1="0.499989" x2="328.583" y2="252.442" gradientUnits="userSpaceOnUse">
            <stop stopColor="#DE98C4" />
            <stop offset="0.15" stopColor="#6272C7" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
          <radialGradient id="radialGradient" cx="0.5" cy="0.5" r="0.5">
            <stop offset="0" stopColor="#fff" stopOpacity="1"></stop>
            <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
          </radialGradient>
          <mask id="maskA">
            <circle cx="0" cy="0" r="150" fill="url(#radialGradient)">
              <animateMotion dur="4s" repeatCount="indefinite" rotate="auto">
                <mpath xlinkHref="#DyBoxPath1"></mpath>
              </animateMotion>
            </circle>
          </mask>
          <mask id="maskB">
            <circle cx="0" cy="0" r="150" fill="url(#radialGradient)">
              <animateMotion dur="4s" repeatCount="indefinite" rotate="auto">
                <mpath xlinkHref="#DyBoxPath2"></mpath>
              </animateMotion>
            </circle>
          </mask>
          <g id="box">
            <path id="DyBoxPath1" fill="transparent" d="M 0.5 1 L 0.5 221 L 345 221 L 345 1 Z"></path>
            <path id="DyBoxPath2" fill="transparent" d="M 345 221 L 345 1 L 0.5 1 L 0.5 221 Z"></path>
          </g>
        </defs>
        
        <g filter="url(#filter0_b_79_367)">
          <rect x="0.5" y="1" width="345" height="220" rx="3.5" stroke="url(#paint0_linear_79_367)" strokeOpacity="0.5" />
          <rect x="0.5" y="1" width="345" height="220" rx="3.5" stroke="url(#paint1_linear_79_367)" />
        </g>
        <use stroke="#6272C7" strokeLinecap="round" strokeWidth="3" xlinkHref="#DyBoxPath1" mask="url(#maskA)">
          <animate attributeName="stroke-dasharray" from="0,1500" to="1500,0" dur="4s" repeatCount="indefinite"></animate>
        </use>
        <use stroke="#DE98C4" strokeLinecap="round" strokeWidth="3" xlinkHref="#DyBoxPath2" mask="url(#maskB)">
          <animate attributeName="stroke-dasharray" from="0,1500" to="1500,0" dur="4s" repeatCount="indefinite"></animate>
        </use>
        
      </svg>
    </Container>
  );
};

export default DynamicCardSVG;

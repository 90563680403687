import React from 'react';
import styled from 'styled-components';

const Btn = styled.button`
  display: inline-block;
  background: rgba(26, 26, 26, 0.2); 
  border: 1px solid #FFADE1;
  color: ${props => props.theme.body};
  outline: none;
  font-size: ${props => props.theme.fontsm};
  padding: 0.9rem 2.3rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  margin-right: 20px;

  &:hover {
    transform: scale(0.95);
    background: rgba(26, 26, 26, 0.4); 

  }

  &:hover::after {
    transform: translate(-50%, -50%) scale(1);
    padding: 0.3rem;
  }

  &:focus {
    background: rgba(26, 26, 26, 0.6); 
    transform: scale(1.02);
    outline: none;
  }

  svg {
    margin: 0 auto;
    display: block; 
  }
  
`;

const Button = ({ svg: Svg, link }) => (
  <a href={link} aria-label="button" target="_blank" rel="noreferrer">
    <Btn>
      <Svg />
    </Btn>
  </a>
);


export default Button;

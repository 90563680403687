import React, {useEffect, useState} from 'react';
import styled, { keyframes } from 'styled-components';
import DynamicCardSVG from './DynamicCardSVG';


const CardWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 3px;
  overflow: hidden;


  &:hover {
    &::before,
    &::after {
      visibility: visible;
    }
  }
`;

const CardContainer = styled.div`
  width: ${(props) => props.width + 'px' || '346px'};
  height: ${(props) => props.height || '221px'};
  background-color: rgba(25, 28, 41, 0.5);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0em;
  color: transparent;
  cursor: pointer;
  font-family: cursive;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
  transition: filter 0.3s ease;
  position: relative;

  ${CardWrapper}:hover & {
    color: white;
  }
`;

const DefaultImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index:99;
`;

const DynamicCard = ({ defaultSvg, hoverSvg, width, height }) => {
  const [isHovered, setIsHovered] = useState(true);

  useEffect(() => {
    setIsHovered(false)
  }, [setIsHovered]);

  return (
    <CardWrapper
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContainer width={width} height={height}>
        {/* {isHovered ? ( */}
        {isHovered ? <DynamicCardSVG backgroundImage={hoverSvg}  /> : '' }
        {/* ) : ( */}
        <div style={{display: !isHovered ? 'block' : 'none'}}>
          <DefaultImage src={defaultSvg} alt="SVG Icon" />
          </div>
        {/* )} */}
      </CardContainer>
    </CardWrapper>
  );
};

export default DynamicCard;
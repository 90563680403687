import React from 'react';
import styled, { keyframes } from 'styled-components';

// 定义关键帧动画
const mouseScrollAnimation = keyframes`
    0% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
`;

// 样式化组件及其子元素
const Wrapper = styled.div`
    position: absolute;
    bottom: 18%;
    @media (max-width: 550px) {
        top: 39%;
    }
`;

const ArrowIndicator = styled.span`
    display: block;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    opacity: 0.8;
    margin: 0 0 -1px;
    animation: ${mouseScrollAnimation} 3s infinite;
    filter: grayscale(70%); /* 添加灰度效果 */

    @media (max-width: 550px) {
        width: 8px;
        height: 8px;
    }

    &:nth-child(1) {
        border-bottom: 1px solid #7A87CD; /* 共同的样式 */
        border-right: 1px solid #7A87CD; /* 第一个ArrowIndicator的右边框样式 */
        animation-delay: 0.3s;
    }
    &:nth-child(2) {
        border-bottom: 1px solid #D7C0CA; /* 共同的样式 */
        border-right: 1px solid #D7C0CA; /* 第二个ArrowIndicator的右边框样式 */
        animation-delay: 0.6s;
    }
    &:nth-child(3) {
        border-bottom: 1px solid #D7C0CA; /* 共同的样式 */
        border-right: 1px solid #D7C0CA; /* 第三个ArrowIndicator的右边框样式 */
        animation-delay: 0.9s;
    }
`;

const ScrollIndicator = () => {
    return (
        <Wrapper>
            <ArrowIndicator />
            <ArrowIndicator />
            <ArrowIndicator />
        </Wrapper>
    );
};

export default ScrollIndicator;

import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
background: rgba(255, 227, 255, 0.20);

    &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); /* 白色半透明背景 */
        border-radius: 50%; /* 圆角效果 */
    }
`;

const IconX = () => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Frame">
<path id="Vector" d="M15.5184 3.84961H17.928L12.6637 9.86636L18.8568 18.0538H14.0077L10.2097 13.0881L5.86394 18.0538H3.45288L9.08356 11.6182L3.14258 3.84961H8.11477L11.5478 8.38839L15.5184 3.84961ZM14.6727 16.6115H16.0079L7.38925 5.21613H5.95644L14.6727 16.6115Z" fill="white"/>
</g>
</svg>

);

const HoverableIconX = () => (
    <IconContainer>
        <IconX />
    </IconContainer>
);

export default HoverableIconX;

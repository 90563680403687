import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ReverseCardSVG from './ReverseCardSVG';



const CardWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 6px;
  overflow: hidden;
  perspective: 1000px; /* 添加透视效果 */

  &::before,

  &:hover {
    &::before,
    &::after {
      visibility: visible;
    }
  }
`;

const CardContainer = styled.div`
  width: ${(props) => props.width || '534px'};
  height: ${(props) => props.height || '289px'};
  background-color: rgba(25, 28, 41, 0.5);
  border-radius: 6px;
  position: relative;
  transition: transform 0.6s ease-in-out;
  transform-style: preserve-3d;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);

  ${CardWrapper}:hover & {
    transform: rotateY(180deg);
  }
`;

const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CardFront = styled(CardFace)`
  /* 正面样式 */
`;

const CardBack = styled(CardFace)`
  /* 背面样式 */
  transform: rotateY(180deg);
`;

const ReverseCard = ({ defaultSvg, hoverSvg, width, height }) => {
  return (
    <CardWrapper>
      <CardContainer width={width} height={height}>
        <CardFront>
          <img src={defaultSvg} alt="SVG Icon" />
        </CardFront>
        <CardBack>
           <ReverseCardSVG backgroundImage={hoverSvg} />
        </CardBack>
      </CardContainer>
    </CardWrapper>
  );
};

export default ReverseCard;

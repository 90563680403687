import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import background from '../assets/Footer/backgroundFoot.svg';
import Discord from '../Icons/Discord';
import X from '../Icons/X';
import DynamicCard from './DynamicCard';
import inita from '../assets/Footer/Initia.svg';
import movement from '../assets/Footer/movement.svg';
import initaH from '../assets/Footer/InitiaH.svg';
import movementH from '../assets/Footer/movementH.svg';
import Milky from '../assets/Footer/Milky.svg';
import MilkyH from '../assets/Footer/MilkyH.svg';

import BlurText from './BlurText';
import {DownToUp} from "./DownToUpFrames";

const Section = styled.section`
  min-height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.body};
  position: relative;
  color: ${props => props.theme.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right center; 
`;

const Container = styled.div`
  width: 75%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 12em;
  @media (max-width: 550px) {
    width: 100%;
    margin-left: 1.3em;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 70px;
  

  @media (max-width: 64em) {
    min-height: 50vh;
  }
`;

const BottomSetting = styled.div`
  font-weight: 400;
  font-size: 18px;
  font-family: 'Inter', sans-serif;
  color: #fff;
  opacity: 0.3;
  width: 95%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 129px;

  a {
    text-decoration: underline;
  }

  @media (max-width: 48em) {
    flex-direction: column;
    width: 100%;
  }
`;

const SubText = styled.h1`
  font: 600 70px Poppins, sans-serif;
  color: ${props => props.theme.text};
  align-self: flex-start;
  width: 100%;
  margin: 1rem auto;
  z-index: 999;

  @media (max-width: 64em) {
    text-align: left;
    font-size: 4em;
  }
  @media (max-width: 550px) {
    text-align: left;
    font-size: 2em;
  }
`;

const BlurTextC = styled.div`
  align-self: flex-start;
  width: 100%;
  margin: 1rem auto;
  z-index: 999;

  @media (max-width: 64em) {
    text-align: left;
  }

  @media (max-width: 550px) {
    padding-right: 2.5em
  }
`;

const Footer = () => {
  const scrollTo = (id) => {
    let element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };

  const titleRef = useRef(null);

  const checkScrollHeightAndLoadAnimation = () => {
    const windowHeight = window.innerHeight;
    const parentElement = document.getElementById("Footer");
    const parentOffsetTop = parentElement.offsetTop;

    const titleElement = titleRef.current;
    if (window.pageYOffset + windowHeight > titleElement.offsetTop + parentOffsetTop + 200) {
      titleElement.classList.add('animate');
      titleElement.classList.remove('hide');
    } else {
      titleElement.classList.add('hide');
      titleElement.classList.remove('animate');
    }
  };

  useEffect(() => {
    checkScrollHeightAndLoadAnimation();
    window.addEventListener('scroll', checkScrollHeightAndLoadAnimation);
    return () => {
      window.removeEventListener('scroll', checkScrollHeightAndLoadAnimation);
    };
  }, []);

  return (
    <Section id = "Footer" >
      <Container>

        <SubText>
          <DownToUp ref={titleRef}>Partnership</DownToUp>
        </SubText>

        <BlurTextC>
        <BlurText
            bgColor="rgba(0, 0, 0, 0)"
            fgColor="rgba(255, 255, 255, 1)"
            fontSize={22}
            text={`At Chai Market, we partner with leading ecosystems<br>and venture capital firms to drive innovation and<br>expand our offerings.`}
            textAlign='left'
          />
        </BlurTextC>
        <Box>
        <a href="https://initia.xyz/"
             target='_blank'
              style={{
                width: '346px',
                height: '221px'
                }
              }
             rel="noopener noreferrer"
             aria-label='initia'>
            <DynamicCard defaultSvg={inita} hoverSvg={initaH} width="346" height="221px"/>
          </a>
          <a href="https://movementlabs.xyz/"
             target='_blank'
             style={{
               width: '346px',
               height: '221px'
             }
             }
             rel="noopener noreferrer"
             aria-label='movement'>
            <DynamicCard defaultSvg={movement} hoverSvg={movementH} width="346" height="221px"/>
          </a>
          {/*</a>*/}
          {/*<a href="https://tucana.zone/"*/}
          {/*   target='_blank'*/}
          {/*   style={{*/}
          {/*     width: '346px',*/}
          {/*     height: '221px'*/}
          {/*   }*/}
          {/*   }*/}
          {/*   rel="noopener noreferrer"*/}
          {/*   aria-label='tucana'>*/}
          {/*  <DynamicCard defaultSvg={tucana} hoverSvg={tucanaH} width="346" height="221px"/>*/}
          {/*</a>*/}
          {/*<a href="https://www.milkyway.zone/"*/}
          {/*   target='_blank'*/}
          {/*   style={{*/}
          {/*     width: '346px',*/}
          {/*     height: '221px'*/}
          {/*   }*/}
          {/*   }*/}
          {/*   rel="noopener noreferrer"*/}
          {/*   aria-label='Milky Way'>*/}
          {/*  <DynamicCard defaultSvg={Milky} hoverSvg={MilkyH} width="346" height="221px"/>*/}
          {/*</a>*/}
        </Box>
      </Container>
      <BottomSetting style={{position: 'absolute',bottom: '0'}}>
        <span>&copy; {new Date().getFullYear()} ChaiMarket. All rights reserved.</span>
        <span>
          <a
              href="https://x.com/Chai_Market"
              target='_blank'
              rel="noopener noreferrer"
              aria-label='X'><X />
          </a>
          <a
             // href="#"
             target='_blank'
             rel="noopener noreferrer"
             aria-label='Discord'><Discord />
          </a>
        </span>
      </BottomSetting>
    </Section>
  );
}

export default Footer;

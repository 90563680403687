import React, { useEffect } from 'react';
import gsap from 'gsap';
import SplitType from 'split-type';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const Lenis = window.Lenis;

const BlurText = ({ bgColor, fgColor, fontSize=28,text, textAlign }) => {
    
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        const splitTypes = document.querySelectorAll('.reveal-type');

        splitTypes.forEach((char, i) => {
            const bg = bgColor;
            const fg = fgColor;

            const text = new SplitType(char, { types: 'chars' });

            gsap.fromTo(
                text.chars,
                {
                    color: bg,
                },
                {
                    color: fg,
                    duration: 0.3,
                    stagger: 0.02,
                    scrollTrigger: {
                        trigger: char,
                        start: 'top 80%',
                        end: 'top 30%',
                        scrub: true,
                        markers: false,
                        toggleActions: 'play play reverse reverse'
                    }
                }
            );
        });

        if (Lenis) {
            const lenis = new Lenis();

            lenis.on('scroll', (e) => {
                console.log(e);
            });

            function raf(time) {
                lenis.raf(time);
                requestAnimationFrame(raf);
            }

            requestAnimationFrame(raf);
        }

        return () => {
            // Clean up GSAP and Lenis instances if needed
        };
    }, [bgColor, fgColor]);

    const renderTextWithLineBreaks = (text) => {
        const lines = text.split(/<br\s*\/?>/gi);
        
        return (
            <React.Fragment>
                {lines.map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </React.Fragment>
        );
    };

    return (
        <section>
            <p className="reveal-type" style={{ 
                fontFamily: 'Poppins, sans-serif',
                fontWeight: 400,
                fontSize: fontSize+'px',
                color: fgColor,
                backgroundColor: bgColor,
                lineHeight: '2',
                textAlign: textAlign // Center text horizontally by default
            }}>
                {renderTextWithLineBreaks(text)}
            </p>
        </section>
    );
};

export default BlurText;

import React, { useState, useEffect } from 'react';
import GlobalStyles from './styles/GlobalStyles';
import { dark } from './styles/Themes'; // Assuming you only need dark theme
import { ThemeProvider } from 'styled-components';

import PgeTest from './components/sections/PgeTest';
import PC from './components/PC';
import Mobile from './components/Mobile';

import Loading from './components/Loading';

function App() {

  const [width, setWidth] = useState(0)
    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    function handleResize() {
        let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        setWidth(width)
    }

  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prevProgress => {
        if (prevProgress >= 100) {
          clearInterval(interval);
          setLoading(false);
          setLoaded(true);
          return 100;
        }
        return prevProgress + 10;
      });
    }, 200);

    return () => clearInterval(interval);
  }, []);

  // 在 loaded 变为 true 后延迟1秒钟
  useEffect(() => {
    if (loaded) {
      const timeout = setTimeout(() => {
        // 在这里可以执行你希望在延迟后执行的操作
        console.log('Loaded and waited 1 second.');
      }, 2000); // 1秒钟的延迟

      return () => clearTimeout(timeout);
    }
  }, [loaded]); // 当 loaded 状态改变时触发

  return (
    <main>
      <GlobalStyles />
      {loading ? (
        <Loading progress={progress} />
      ) : (
        loaded && (
          <ThemeProvider theme={dark}>
           
            {/* <PgeTest/>
            <Footer /> */}

              {width > 768 ? <PC /> : <Mobile />}
          </ThemeProvider>
        )
      )}
    </main>
  );
}

export default App;

import Navigation from './Navigation';
import Home from './sections/Home';
import Footer from './Footer';
import Pge2 from './sections/Pge2';
import Card from './sections/Card';
import RoadMap from './sections/ChaiRoadMap';

const PC = () => {
    return (
        <section>
        <Navigation />
        <Home />
        <Pge2 />
        <Card />
        <RoadMap />
        <Footer />
        </section>
    )
}

export default PC;

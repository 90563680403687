import * as React from "react";
import styled from "styled-components";  // 确保导入 styled-components

const Svg = styled.svg`
  fill: ${props => props.theme.text};  // 使用主题颜色设置填充颜色
`;

const Discord = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M33.9932 14.8303C32.1649 13.978 30.1854 13.3594 28.1235 13.002C28.1054 13.0014 28.0874 13.0048 28.0708 13.012C28.0542 13.0191 28.0393 13.0298 28.0273 13.0433C27.7798 13.4969 27.4911 14.088 27.2987 14.5416C25.1116 14.2117 22.8875 14.2117 20.7004 14.5416C20.508 14.0742 20.2193 13.4969 19.9581 13.0433C19.9444 13.0158 19.9031 13.002 19.8619 13.002C17.7999 13.3594 15.8342 13.978 13.9922 14.8303C13.9784 14.8303 13.9647 14.844 13.9509 14.8578C10.2119 20.4526 9.18094 25.8961 9.68955 31.2847C9.68955 31.3122 9.7033 31.3397 9.73079 31.3535C12.2051 33.168 14.5833 34.2677 16.9339 34.9963C16.9751 35.01 17.0164 34.9963 17.0301 34.9688C17.58 34.2127 18.0749 33.4154 18.501 32.5769C18.5285 32.5219 18.501 32.4669 18.446 32.4532C17.6625 32.1508 16.9202 31.7933 16.1916 31.381C16.1366 31.3535 16.1366 31.271 16.1779 31.2297C16.3291 31.1198 16.4803 30.9961 16.6315 30.8861C16.659 30.8586 16.7002 30.8586 16.7277 30.8723C21.4565 33.0305 26.5564 33.0305 31.2302 30.8723C31.2577 30.8586 31.2989 30.8586 31.3264 30.8861C31.4776 31.0098 31.6288 31.1198 31.78 31.2435C31.835 31.2847 31.835 31.3672 31.7663 31.3947C31.0515 31.8208 30.2954 32.1645 29.5119 32.4669C29.4569 32.4807 29.4431 32.5494 29.4569 32.5906C29.8968 33.4292 30.3916 34.2265 30.9277 34.9825C30.969 34.9963 31.0102 35.01 31.0515 34.9963C33.4158 34.2677 35.794 33.168 38.2683 31.3535C38.2958 31.3397 38.3096 31.3122 38.3096 31.2847C38.9144 25.0576 37.3061 19.6553 34.0482 14.8578C34.0344 14.844 34.0207 14.8303 33.9932 14.8303ZM19.2158 27.9993C17.7999 27.9993 16.6177 26.6934 16.6177 25.0851C16.6177 23.4768 17.7724 22.1709 19.2158 22.1709C20.6729 22.1709 21.8276 23.4905 21.8139 25.0851C21.8139 26.6934 20.6592 27.9993 19.2158 27.9993ZM28.7971 27.9993C27.3812 27.9993 26.199 26.6934 26.199 25.0851C26.199 23.4768 27.3537 22.1709 28.7971 22.1709C30.2542 22.1709 31.4089 23.4905 31.3951 25.0851C31.3951 26.6934 30.2542 27.9993 28.7971 27.9993Z" fill="white"/>
</svg>

);

export default Discord;

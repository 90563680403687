import React, { lazy, Suspense } from 'react';
import styled from 'styled-components';
import Loading from '../Loading';
import ScrollIndicator from '../ScrollIndicator.js';
import videoBackground from '../../assets/Home Video.mp4'; 


const TypeWriterText = lazy(() => import('../TypeWriterText'));

const Section = styled.section`
  min-height: ${props => `calc(100vh - ${props.theme.navHeight})`};
  width: 100vw;
  position: relative;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient( rgba(13, 13, 18, 1) 0%, rgba(13, 13, 18, 0.23) 23%, rgba(13, 13, 18, 0) 51%, rgba(13, 13, 18, 0.2) 70%, rgba(13, 13, 18, 1) 96%, rgba(13, 13, 18, 1) 100%); /* 黑色渐变到透明 */

`;

const Container = styled.div`
  width: 75%;
  min-height: 311px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 64em) {
    width: 85%;
  }
  @media (max-width: 48em) {
    flex-direction: column-reverse;
    width: 100%;
    & > *:first-child {
      width: 100%;
      margin-top: 2rem;
    }
  }
`;

const Box = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Home = () => {
  return (
    <Section id="home">
      <VideoBackground autoPlay loop muted>
        <source src={videoBackground} type="video/mp4" preload="auto" />
        Your browser does not support the video tag.
      </VideoBackground>
      <Overlay />
      <Container>
        <Box>
          <Suspense fallback={<Loading />}>
            <TypeWriterText />
          </Suspense>
        </Box>
        <ScrollIndicator /> 
      </Container>
    </Section>
  );
};

export default Home;

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import TimelineArrow from '../Icons/TimelineArrow';

const clipPathAnimation = keyframes`
  0% {
    clip-path: inset(0 100% 0 0); 
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 100%); 
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const LinerContainer = styled.div`
  width: 100%;
  margin: -19px auto;

  z-index: -22;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
  
`;

const TimelineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.5%;
  align-items: flex-start;

`;

const Event = styled.div`
  position: relative;
  padding: 10px;
  margin-right: 20px;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  // opacity: ${props => (props.isSelected ? '1' : '0.2')};
`;

const EventTime = styled.div`
  width: 345.33px;
  font: 38px Inter, sans-serif;
  font-weight: light;
  margin-bottom: 18px;
  background: linear-gradient(90deg, #101010 0.5%, #6272C7 10%, #DE98C4 30%, #D7C0CA 41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: transform 0.3s ease;

  ${props => props.isSelected && css`
    transform: scale(1.28); /* 放大1.1倍 */
    margin-left: 10%;
  `}
`;

const EventContent = styled.div`
  width: 345.33px;
  height:160px;
  font: 16px Inter, sans-serif;
  background: linear-gradient(-90deg, #6272C7 0%, #DE98C4 70%,#D7C0CA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10px;
  white-space: pre-wrap;
  transition: transform 0.3s ease;



  ${props => props.isSelected && css`
  font: 16px Inter, sans-serif;
  
  `}
`;

const TimelineLine = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #6272C7, #DE98C4);
  position: absolute;
  top: 40%;
  font: 38px Inter, sans-serif;
  // box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: ${clipPathAnimation} 6s infinite linear;
  @media (max-width: 550px) {
    width: 150%!important;
  }
`;

const TimelineLine2 = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(98, 114, 199, 0.5), rgba(222, 152, 196, 0.5));
  position: absolute;
  top: 40%;
  font: 38px Inter, sans-serif;
  // box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  @media (max-width: 550px) {
    width: 150%!important;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 91.5%;
  left: -1.5%;
`;

const Timeline = ({ events }) => {
  const [selectedEventIndex, setSelectedEventIndex] = React.useState(1);

  const handleEventHover = index => {
    setSelectedEventIndex(index);
  };

  return (
    <TimelineWrapper>
      <Container>
        <div style={{
          width: '100%', paddingTop: '4em',
          paddingLeft: '5em'
        }}>
          {events.map((event, index) => (
            <div
              style={{ width: '100%',paddingLeft:'1.5em' }}
              key={index}
            // isSelected={index === selectedEventIndex}
            // onMouseEnter={() => handleEventHover(index)}
            >
              {/* <ArrowWrapper>
                <TimelineArrow />
              </ArrowWrapper> */}
              <svg style={{position: 'absolute',left: '4em',top: index == 0 ? '5em' : index == 1 ? '19em' : '33em'}} xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
                <path d="M18.3664 3.58189C18.8989 3.42212 19.3318 4.02724 19.0087 4.47968L10.4747 16.4272C10.242 16.753 9.75797 16.753 9.52532 16.4272L0.991342 4.47968C0.668175 4.02724 1.10109 3.42212 1.63364 3.58189L9.83238 6.04151C9.94172 6.07431 10.0583 6.07431 10.1676 6.04151L18.3664 3.58189Z" fill="#D9D9D9" />
                <path d="M18.3664 3.58189C18.8989 3.42212 19.3318 4.02724 19.0087 4.47968L10.4747 16.4272C10.242 16.753 9.75797 16.753 9.52532 16.4272L0.991342 4.47968C0.668175 4.02724 1.10109 3.42212 1.63364 3.58189L9.83238 6.04151C9.94172 6.07431 10.0583 6.07431 10.1676 6.04151L18.3664 3.58189Z" fill="url(#paint0_linear_900_3585)" />
                <defs>
                  <linearGradient id="paint0_linear_900_3585" x1="10" y1="-1.57487" x2="10" y2="23.5085" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#101010" />
                    <stop offset="0.28" stop-color="#6272C7" />
                    <stop offset="0.7" stop-color="#FFADE1" />
                  </linearGradient>
                </defs>
              </svg>
              <EventTime isSelected={index === selectedEventIndex}>{event.time}</EventTime>
              <EventContent isSelected={index === selectedEventIndex} className="event-content">{event.description}</EventContent>
            </div>
          ))}
        </div>
        <LinerContainer style={{ transform: 'rotate(90deg)', position: 'absolute', left: '-8em', top: '19.3em' }}>
          <TimelineLine>Sample Text</TimelineLine>
          <TimelineLine2 />


        </LinerContainer>
      </Container>
    </TimelineWrapper>
  );
};

export default Timeline;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const LogoImage = styled.img`
  width: 104.73px; /* 设置图片宽度为父容器宽度的百分比 */
  height: auto; /* 自动计算高度，保持比例 */
  margin-left: 12px;
  transition: all 0.2s ease;
  width: 4.90906rem;
height: 1.5rem;
flex-shrink: 0;
  &:hover {
    transform: scale(1.1);
  }

`;

const MoblieLogo = () => {
  return (
    <Link to="/">
      <LogoImage src="logo192.png" alt="Logo" />
    </Link>
  );
};

export default MoblieLogo;

import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import TimelineArrow from '../Icons/TimelineArrow';

const clipPathAnimation = keyframes`
  0% {
    clip-path: inset(0 100% 0 0); 
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  100% {
    clip-path: inset(0 0 0 100%); 
  }
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
  
`;

const LinerContainer = styled.div`
  width: 100%;
  margin: -19px auto;

  z-index: -22;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }

`;

const TimelineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const EventsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 3.5%;
  align-items: flex-start;
  @media (max-width: 1050px) {
     margin-left: 19em;
  }
`;

const Event = styled.div`
  position: relative;
  padding: 10px;
  margin-right: 20px;
  color: white;
  cursor: pointer;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: ${props => (props.isSelected ? '1' : '0.2')};
`;

const EventTime = styled.div`
  width: 345.33px;
  font: 38px Inter, sans-serif;
  font-weight: light;
  margin-bottom: 18px;
  background: linear-gradient(90deg, #101010 0.5%, #6272C7 10%, #DE98C4 30%, #D7C0CA 41%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: transform 0.3s ease;

  ${props => props.isSelected && css`
    transform: scale(1.28); /* 放大1.1倍 */
    margin-left: 10%;
  `}
`;

const EventContent = styled.div`
  width: 345.33px;
  height:160px;
  font: 16px Inter, sans-serif;
  background: linear-gradient(-90deg, #6272C7 0%, #DE98C4 70%,#D7C0CA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top: 10px;
  white-space: pre-wrap;
  transition: transform 0.3s ease;



  ${props => props.isSelected && css`
  font: 16px Inter, sans-serif;
  
  `}
`;

const TimelineLine = styled.div`
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #6272C7, #DE98C4);
  position: absolute;
  top: 40%;
  font: 38px Inter, sans-serif;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  animation: ${clipPathAnimation} 6s infinite linear;
`;

const TimelineLine2 = styled.div`
  width: 100%;
  height: 0.5px;
  background: linear-gradient(to right, rgba(98, 114, 199, 0.5), rgba(222, 152, 196, 0.5));
  position: absolute;
  top: 40%;
  font: 38px Inter, sans-serif;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  @media (max-width: 1050px) {
    width: 100%!important;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  top: 91.5%;
  left: -2%;
`;

const Timeline = ({ events }) => {
  const [selectedEventIndex, setSelectedEventIndex] = React.useState(1);

  const handleEventHover = index => {
    setSelectedEventIndex(index);
  };

  return (
    <TimelineWrapper>

      
      

      <Container>
        <EventsWrapper>
          {events.map((event, index) => (
            <Event
              key={index}
              isSelected={index === selectedEventIndex}
              onMouseEnter={() => handleEventHover(index)}
            >
              <ArrowWrapper>
                <TimelineArrow />
              </ArrowWrapper>
              <EventTime isSelected={index === selectedEventIndex}>{event.time}</EventTime>
            </Event>
          ))}
        </EventsWrapper>
        <LinerContainer>
        <TimelineLine>Sample Text</TimelineLine>
        <TimelineLine2 />


      </LinerContainer>
        <EventsWrapper>
          {events.map((event, index) => (
            <Event
              key={index}
              isSelected={index === selectedEventIndex}
              onMouseEnter={() => handleEventHover(index)}
            >
              <EventContent isSelected={index === selectedEventIndex} className="event-content">{event.description}</EventContent>
            </Event>
          ))}
        </EventsWrapper>
      </Container>
    </TimelineWrapper>
  );
};

export default Timeline;

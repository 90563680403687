import React from 'react';
import styled, { keyframes } from 'styled-components';

// Assuming you have a logo image imported
import Logo from './Logo';

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 150;
  background-color: #000;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin-top: 0rem;
  margin-left: 5%;

  border: 4px solid #222;
  border-radius: 50%;
  border-top: 4px solid #999;
  width: 1.5rem;
  height: 2rem;
  -webkit-animation: ${spin} 0.1s linear infinite; /* Safari */
  animation: ${spin} 1.5s linear infinite;
`;

const ProgressText = styled.div`
  position: absolute;
  bottom: 40px;
  left: 70px;
  color: #fff;
  font-size: 4rem;
`;

const Loading = ({ progress }) => {
  return (
    <Loader>
      <Container>
        <Logo />
      </Container>
      <ProgressText>{progress}%</ProgressText>
    </Loader>
  );
};

export default Loading;

import React from 'react';

const TimelineArrow = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4.82212 1.63364C4.66236 1.10109 5.26748 0.668176 5.71991 0.991343L17.6675 9.52532C17.9932 9.75797 17.9932 10.242 17.6675 10.4747L5.71991 19.0087C5.26748 19.3318 4.66236 18.8989 4.82212 18.3664L7.28175 10.1676C7.31455 10.0583 7.31455 9.94172 7.28175 9.83238L4.82212 1.63364Z" fill="#D9D9D9"/>
<path d="M4.82212 1.63364C4.66236 1.10109 5.26748 0.668176 5.71991 0.991343L17.6675 9.52532C17.9932 9.75797 17.9932 10.242 17.6675 10.4747L5.71991 19.0087C5.26748 19.3318 4.66236 18.8989 4.82212 18.3664L7.28175 10.1676C7.31455 10.0583 7.31455 9.94172 7.28175 9.83238L4.82212 1.63364Z" fill="url(#paint0_linear_1_113)"/>
<defs>
<linearGradient id="paint0_linear_1_113" x1="-0.334635" y1="10" x2="24.7487" y2="10" gradientUnits="userSpaceOnUse">
<stop stopColor="#101010"/>
<stop offset="0.28" stopColor="#6272C7"/>
<stop offset="0.7" stopColor="#FFADE1"/>
</linearGradient>
</defs>
</svg>


);

export default TimelineArrow;

import React, { lazy, Suspense } from 'react'
import styled, { ThemeProvider } from 'styled-components'

import Loading from '../Loading';
import background from '../../assets/Page3/backgroundP3.svg';
import CardSwiperText from '../CardSwiperText.js'; 




const CarouselTest = lazy(() => import("../CarouselTest"));

const Section = styled.section`
min-height: 100vh;
width: 100%;
background-color: ${props => props.theme.body};
display: flex;
justify-content: center;
align-items: center;
position: relative;
overflow: hidden;
`
const Container = styled.div`
width: 80%;
margin: 0 auto;
display: flex;
justify-content: center;
align-items: stretch;


@media (max-width: 70em){
  width: 85%;
}

@media (max-width: 64em){
  width: 100%;
  flex-direction: column;

  &>*:last-child{
    width: 80%;
  }
}
@media (max-width: 40em){
  

  &>*:last-child{
    width: 90%;
  }
}
`

const TextContainer = styled.div`
width: 70%;
margin: 0 auto;
display: column;
// flex-direction: column;
z-index: 999;


justify-content: center;
align-items: center;


`;

const SwiperTextContainer = styled.div`
width: 100%;
margin: 0 auto;
display: flex;
z-index: 999;


justify-content: center;
align-items: center;


`;

const Box = styled.div`
  width: 45%;
  height: 100%;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease; 
  margin-top:10%;

  @media (max-width: 40em){
    min-height: 50vh;
  }

  
`;

 
const SubText = styled.h1`
  font: 600 70px Poppins, sans-serif;
  
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  width: 100%;
  margin: 0rem auto;
  margin-left: 5.5%; 

  .with {
    
    display: block;
    font-weight: 900;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
  }


  @media (max-width: 64em) {
    margin-left: 0%; 

    width: 100%;
    text-align: left;
    font-size: 90px;
  }

  @media (max-width: 40em) {
    margin-left: 0%; 

    font-size: 50px;
    text-align: left;
  }

  @media (max-width: 30em) {
    margin-left: 0%; 

    font-size: 35px;
    text-align: left;

  }

`;


const SubTitle = styled.h2`
  font: 600 90px Poppins, sans-serif; 
  font-size: 28px; 
  color: ${(props) => props.theme.text};
  margin-top: 60px; 
  z-index: 999; 


  @media (max-width: 64em) {
    text-align: center;
  }
`;

const CenteredSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 75%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  z-index: 1; 
`;

const Card = () => {
  return (
    <Section id="Card">
      <Container>
        <TextContainer>
        <SubText>
            Chai Market
            <span className="with">Pros</span>

            {/* <SubTitle>
            Seamless Interoperability
            </SubTitle> */}
        </SubText>
        <SwiperTextContainer>


        </SwiperTextContainer>

        </TextContainer>

        

      
          <Box> 
          <Suspense fallback={<Loading />}>
            <CarouselTest /> </Suspense> </Box>
        
      </Container>
      <CenteredSVG>
        <image href={background} />
      </CenteredSVG>
    </Section>
  )
}

export default Card
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Pagination, Navigation, Autoplay, EffectCoverflow } from "swiper";

import img1 from '../assets/Page3/card-1.svg';
import img2 from '../assets/Page3/card-2.svg';
import img3 from '../assets/Page3/card-3.svg';

const slideInUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 91em){
      width: 45vw;
      height: 55vh;
  }

  @media (max-width: 64em){
      width: 88vw;
      transform: scale(0.9);
  }

  @media (max-width: 48em){
      width: 101vw;
      transform: scale(0.8);
  }

  @media (max-width: 30em){
      width: 150vw;
      transform: scale(0.7);
  }

  .swiper-container {
    width: 70%;
    height: 100%;
    padding-right: 25%; 
  }

  .swiper-slide {
    background-color: rgba(255, 255, 255, 0.06);
    border: 1px solid rgba(255, 255, 255, 0.16);
    border-radius: 5px;
    padding: 32px;
    width: 313px;
    height: 375px;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: ${props => props.theme.text};
    top: 50%;
    width: 4rem;
    background-position: center;
    background-size: cover;

    &:after {
      display: none;
    }

    @media (max-width: 64em){
      width: 3rem;
    }
    @media (max-width: 30em){
      width: 2rem;
    }
  }

  .swiper-button-prev {
    left: 0;
    top: 60%;
    transform: rotate(180deg);
  }

  .swiper-pagination {
    display: none;
  }

  .text-container {
    
    font: 400 28px Poppins, sans-serif;
    position: absolute;
    left: -115%;
    top: 28%;
    transform: translate(-50%, -50%);
    width: 30%;
      text-align: center;
    z-index: 10;
    opacity: 0;
    white-space: nowrap;

    &.active {
      animation: ${slideInUp} 0.5s ease-in-out forwards;
    }

    @media (max-width: 90em){
      left: -83%;
    } 
  }

  .text {
    margin-bottom: 20px;
    color: ${props => props.theme.text};
    font-size: 28px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  
`;

const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };

  return (
    <Container>
      <div className={`text-container `}>
        <div className={`text ${activeIndex === 0 ? 'active' : ''}`}>Seamless Interoperability</div>
      </div>
      <div className={`text-container ${activeIndex === 1 ? 'active' : ''}`}>
        <div className={`text ${activeIndex === 1 ? 'active' : ''}`}>Commitment to Security</div>
      </div>
      <div className={`text-container ${activeIndex === 2 ? 'active' : ''}`}>
        <div className={`text ${activeIndex === 2 ? 'active' : ''}`}>Innovative Strategies</div>
      </div>
      <Swiper
        onSlideChange={handleSlideChange}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: -50,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        navigation={true}
        className="swiper-container"
      >
        <SwiperSlide><img src={img1} alt="card1" /></SwiperSlide>
        <SwiperSlide><img src={img2} alt="card2" /></SwiperSlide>
        <SwiperSlide><img src={img3} alt="card3" /></SwiperSlide>
      </Swiper>
    </Container>
  );
}

export default Carousel;

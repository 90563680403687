import React, { lazy, Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loading from '../Loading.js';
import background from '../../assets/RoadMap/backgroundP3.svg';
import groupbackground from '../../assets/groupbackground.png';
import Timeline from '../MobileTimeline.js';
import { DownToUp } from "../DownToUpFrames.js";

const Carousel = lazy(() => import("../Carousel.js"));

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 550px){
    margin-top: -9em;
  }
`;

const Container = styled.div`
  width: 80%;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const SubText = styled.h1`
  font: 600 70px Poppins, sans-serif;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  width: 100%;
  margin: 1rem auto 1rem 3%;
  z-index: 999;
  /* 添加以下样式来向右移动 */
  @media (max-width: 550px) {
    margin-top: -5em;
    margin-left: 0.72em;
  }

  @media (max-width: 64em) {
    width: 100%;
    text-align: left;
    font-size: 90px;
  }

  @media (max-width: 40em) {
    font-size: 50px;
    text-align: left;
  }

  @media (max-width: 30em) {
    font-size: 35px;
    text-align: left;
    margin-top: 0em;
  }

`;

const CenteredSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 999;
  margin-top:5.5%;

  @media (max-width: 64em){
    min-height: 50vh;
    transform: scale(0.7);
  }

  @media (max-width: 40em) {
    width: 100%;
    transform: scale(0.32);
  }
  @media (max-width: 40em) {
    width: 100%;
    transform: scale(0.9);
  }
`;

const eventsData = [
  {
    time: "Q2 2024",
    description: "• Chai Engine interoperability on testnet\n• Risk Engine on testnet with status monitor\n• Liquidity Master on testnet\n"
  },
  { time: 'Q3 2024', description: '• Execution Model/Bot on mainnet\n• Real-time risk calculator\n• Liquidity and Lending Master on mainnet\n• Staking Lever on mainnet\n• Latte Cups on mainnet\n• $CHAI TGE' },
  { time: 'Q4 2024', description: '• Chai Engine v2\n• Risk Engine upgrade\n• Alpha Model strategies expansion\n• TVL incentives bootstrap' },
];

const RoadMap = () => {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const titleRef = useRef(null);
  const timeLineRef = useRef(null);

  const checkScrollHeightAndLoadAnimation = () => {
    const windowHeight = window.innerHeight;
    const parentElement = document.getElementById("ChaiRoadmap");
    const parentOffsetTop = parentElement.offsetTop;

    const titleElement = titleRef.current;
    if (window.pageYOffset + windowHeight > titleElement.offsetTop + parentOffsetTop + 200) {
      titleElement.classList.add('animate');
      titleElement.classList.remove('hide');
    } else {
      titleElement.classList.add('hide');
      titleElement.classList.remove('animate');
    }
    const timeLineElement = timeLineRef.current;
    if (window.pageYOffset + windowHeight > timeLineElement.offsetTop + parentOffsetTop + 200) {
      timeLineElement.classList.add('animate');
      timeLineElement.classList.remove('hide');
    } else {
      timeLineElement.classList.add('hide');
      timeLineElement.classList.remove('animate');
    }
  };

  useEffect(() => {
    checkScrollHeightAndLoadAnimation();
    window.addEventListener('scroll', checkScrollHeightAndLoadAnimation);
    return () => {
      window.removeEventListener('scroll', checkScrollHeightAndLoadAnimation);
    };
  }, []);
  return (
    <Section id="ChaiRoadmap" style={{minHeight: '104vh',zIndex: '999'}}>
      <Container>
        <DownToUp ref={titleRef}>
          <SubText>Roadmap of<br />2024</SubText>
        </DownToUp>
        <Box>
          <DownToUp ref={timeLineRef}>
            <Timeline events={eventsData} />
          </DownToUp>
        </Box>
      </Container>
      {width > 768 ? <CenteredSVG viewBox="0 0 1440 709" preserveAspectRatio="none">
        <image href={background} width="100%" height="100%" />
      </CenteredSVG> : <div style={{ position: 'absolute',height: '703px',  top: '10em' ,width: '100%'}}><div></div>
        {/* <svg width="380" height="699" viewBox="0 0 380 699" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_1_154" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="380" height="699">
            <rect width="380" height="699" fill="url(#paint0_linear_1_154)" />
          </mask>
          <g mask="url(#mask0_1_154)">
            <path d="M473.685 170.339L473.685 72.9027C473.604 72.7946 473.495 72.6516 473.356 72.4756C473 72.0226 472.45 71.3508 471.686 70.4914C470.157 68.7727 467.77 66.3032 464.359 63.3324C457.538 57.3913 446.618 49.4416 430.272 41.4817C397.582 25.5627 343.162 9.58903 256.385 9.58903C169.609 9.58903 113.712 25.5624 79.5436 41.489C62.4581 49.4529 50.7985 57.4082 43.4225 63.3567C39.7341 66.3311 37.116 68.8045 35.4255 70.5272C34.5802 71.3886 33.9668 72.0624 33.5674 72.5174C33.4178 72.6878 33.2982 72.8276 33.2077 72.935L33.2077 170.339L473.685 170.339Z" fill="url(#paint1_linear_1_154)" fill-opacity="0.5" stroke="url(#paint2_linear_1_154)" stroke-width="2" />
            <path d="M-94.5887 535.933L-94.5887 633.812C-94.5075 633.92 -94.398 634.065 -94.2589 634.242C-93.9028 634.697 -93.3529 635.372 -92.5884 636.236C-91.0596 637.962 -88.6725 640.443 -85.2615 643.427C-78.4403 649.395 -67.5202 657.38 -51.1744 665.375C-18.4847 681.365 35.935 697.41 122.711 697.41C209.486 697.41 265.383 681.365 299.551 665.368C316.636 657.368 328.296 649.378 335.672 643.402C339.36 640.415 341.978 637.93 343.669 636.2C344.514 635.334 345.128 634.658 345.527 634.201C345.678 634.028 345.798 633.888 345.888 633.779L345.888 535.933L-94.5887 535.933Z" fill="url(#paint3_linear_1_154)" fill-opacity="0.5" stroke="url(#paint4_linear_1_154)" stroke-width="2" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_1_154" x1="190" y1="699" x2="190" y2="-1.04159e-05" gradientUnits="userSpaceOnUse">
              <stop stop-color="#D9D9D9" stop-opacity="0.5" />
              <stop offset="0.234621" stop-color="#B5B5B5" stop-opacity="0" />
              <stop offset="0.763949" stop-color="#AAAAAA" stop-opacity="0" />
              <stop offset="1" stop-color="#737373" stop-opacity="0.5" />
            </linearGradient>
            <linearGradient id="paint1_linear_1_154" x1="47.7406" y1="125.435" x2="474.736" y2="128.983" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
            <linearGradient id="paint2_linear_1_154" x1="47.7406" y1="125.435" x2="474.736" y2="128.983" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
            <linearGradient id="paint3_linear_1_154" x1="331.356" y1="581.042" x2="-95.6404" y2="577.51" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
            <linearGradient id="paint4_linear_1_154" x1="331.356" y1="581.042" x2="-95.6404" y2="577.51" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
          </defs>
        </svg> */}
        <img style={{width: '100%'}} src={groupbackground}></img>
      </div>}

    </Section>
  );
};

export default RoadMap;

import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import ReverseCard from '../ReverseCard';
import Ecosystem from '../../assets/Page2/Ecosystem-Integrator.svg';
import Liquidity from '../../assets/Page2/Liquidity-Aggregator.svg';
import EcosystemH from '../../assets/Page2/Ecosystem-IntegratorH.svg';
import LiquidityH from '../../assets/Page2/Liquidity-AggregatorH.svg';

import BackgrounP2 from '../../assets/Page2/BackgrounP2.svg';

import BlurText from '../BlurText.js';

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 100em) {
    width: 100%;
  }

  @media (max-width: 100em) {
    width: 100%;
  }
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;

  @media (max-width: 64em) {
    min-height: 50vh;
  }
`;

const CenteredSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 75%;
  left: 78%;
  transform: translate(-50%, -50%);
  z-index: 1;

  @media (max-width: 64em) {
    width: 50%;
    left: 80%;
  }
`;

const SubTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: Bottom; /* Bottom align */
  margin-top: 13em; /* Adjust top margin as needed */

  @media (min-width: 40em) {
      width: 100%;
      margin-top: 20%;
  }
`;

const showLeft = keyframes`
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const showRight = keyframes`
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const hideLeft = keyframes`
    to {
        opacity: 0;
        transform: translateX(-100px);
    }
`;

const hideRight = keyframes`
    to {
        opacity: 0;
        transform: translateX(100px);
    }
`;

const LeftItem = styled.div`
    opacity: 0;
    transform: translateX(-100px);
    &.animate {
        animation: ${showLeft} 0.8s forwards;
    }
    &.hide {
        animation: ${hideLeft} 0s forwards;
    }
`;

const RightItem = styled.div`
    opacity: 0;
    transform: translateX(100px);
    &.animate {
        animation: ${showRight} 0.8s forwards;
    }
    &.hide {
        animation: ${hideRight} 0s forwards;
    }
`;

const Pge2 = () => {
    const leftItemRef = useRef(null);
    const rightItemRef = useRef(null);

    const checkScrollHeightAndLoadAnimation = () => {
        const windowHeight = window.innerHeight;
        const parentElement = document.getElementById("Page2");
        const parentOffsetTop = parentElement.offsetTop;

        const leftElement = leftItemRef.current;
        if (window.pageYOffset + windowHeight > leftElement.offsetTop + parentOffsetTop + 200) {
            leftElement.classList.add('animate');
            leftElement.classList.remove('hide');
        } else {
            leftElement.classList.add('hide');
            leftElement.classList.remove('animate');
        }

        const rightElement = rightItemRef.current;
        if (window.pageYOffset + windowHeight > rightElement.offsetTop + parentOffsetTop + 200) {
            rightElement.classList.add('animate');
            rightElement.classList.remove('hide');
        } else {
            rightElement.classList.add('hide');
            rightElement.classList.remove('animate');
        }
    };

    useEffect(() => {
        checkScrollHeightAndLoadAnimation();
        window.addEventListener('scroll', checkScrollHeightAndLoadAnimation);
        return () => {
            window.removeEventListener('scroll', checkScrollHeightAndLoadAnimation);
        };
    }, []);

    return (
        <Section id="Page2">
            <Container>
                <Box>
                    <LeftItem ref={leftItemRef}>
                        <ReverseCard defaultSvg={Ecosystem} hoverSvg={EcosystemH} width="534px" height="289px" />
                    </LeftItem>
                    <RightItem ref={rightItemRef}>
                        <ReverseCard defaultSvg={Liquidity} hoverSvg={LiquidityH} width="534px" height="289px" />
                    </RightItem>
                </Box>
                <SubTitleContainer>
                    <BlurText
                        bgColor="rgba(0, 0, 0, 0)"
                        fgColor="rgba(255, 255, 255, 1)"
                        text={`Unlock the full potential of the Modular Ecosystem<br>with cross-chain yield opportunities and dynamic<br>risk management`}
                        textAlign='center'
                    />
                </SubTitleContainer>
            </Container>
            <CenteredSVG>
                <image xlinkHref={BackgrounP2} />
            </CenteredSVG>
        </Section>
    );
};

export default Pge2;
import React, { lazy, Suspense, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Loading from '../Loading';
import background from '../../assets/RoadMap/backgroundP3.svg';
import Timeline from '../Timeline.js';
import { DownToUp } from "../DownToUpFrames";

const Carousel = lazy(() => import("../Carousel"));

const Section = styled.section`
  min-height: 100vh;
  width: 100%;
  background-color: ${props => props.theme.body};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 550px){
    margin-top: -11em;
  }
`;

const Container = styled.div`
  width: 80%;
  margin: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 70em) {
    width: 85%;
  }

  @media (max-width: 64em) {
    width: 100%;
    flex-direction: column;

    & > *:last-child {
      width: 80%;
    }
  }

  @media (max-width: 40em) {
    & > *:last-child {
      width: 90%;
    }
  }
`;

const SubText = styled.h1`
  font: 600 70px Poppins, sans-serif;
  color: ${(props) => props.theme.text};
  align-self: flex-start;
  width: 100%;
  margin: 1rem auto 1rem 3%;
  z-index: 999;
  /* 添加以下样式来向右移动 */
  @media (max-width: 550px) {
    margin-top: -5em;
    margin-left: 0.72em;
  }

  @media (max-width: 64em) {
    width: 100%;
    text-align: left;
    font-size: 90px;
  }

  @media (max-width: 40em) {
    font-size: 50px;
    text-align: left;
  }

  @media (max-width: 30em) {
    font-size: 35px;
    text-align: left;

  }

`;

const CenteredSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Box = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 999;
  margin-top:5.5%;

  @media (max-width: 5000px){
    transform: scale(1.4);
  }

  @media (max-width: 2000px){
    transform: scale(1.1);
  }

  @media (max-width: 1400px){
    transform: scale(0.8);
  }

  @media (max-width: 64em){
    min-height: 50vh;
    transform: scale(0.7);
  }

  @media (max-width: 40em) {
    width: 100%;
    transform: scale(0.32);
  }
`;

const eventsData = [
  {
    time: "Q2 2024",
    description: "• Chai Engine interoperability on testnet\n• Risk Engine on testnet with status monitor\n• Liquidity Master on testnet\n"
  },
  { time: 'Q3 2024', description: '• Execution Model/Bot on mainnet\n• Real-time risk calculator\n• Liquidity and Lending Master on mainnet\n• Staking Lever on mainnet\n• Latte Cups on mainnet\n• $CHAI TGE' },
  { time: 'Q4 2024', description: '• Chai Engine v2\n• Risk Engine upgrade\n• Alpha Model strategies expansion\n• TVL incentives bootstrap' },
];

const RoadMap = () => {
  let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  const titleRef = useRef(null);
  const timeLineRef = useRef(null);

  const checkScrollHeightAndLoadAnimation = () => {
    const windowHeight = window.innerHeight;
    const parentElement = document.getElementById("ChaiRoadmap");
    const parentOffsetTop = parentElement.offsetTop;

    const titleElement = titleRef.current;
    if (window.pageYOffset + windowHeight > titleElement.offsetTop + parentOffsetTop + 200) {
      titleElement.classList.add('animate');
      titleElement.classList.remove('hide');
    } else {
      titleElement.classList.add('hide');
      titleElement.classList.remove('animate');
    }
    const timeLineElement = timeLineRef.current;
    if (window.pageYOffset + windowHeight > timeLineElement.offsetTop + parentOffsetTop + 200) {
      timeLineElement.classList.add('animate');
      timeLineElement.classList.remove('hide');
    } else {
      timeLineElement.classList.add('hide');
      timeLineElement.classList.remove('animate');
    }
  };

  useEffect(() => {
    checkScrollHeightAndLoadAnimation();
    window.addEventListener('scroll', checkScrollHeightAndLoadAnimation);
    return () => {
      window.removeEventListener('scroll', checkScrollHeightAndLoadAnimation);
    };
  }, []);
  return (
    <Section id="ChaiRoadmap">
      <Container>
        <DownToUp ref={titleRef}>
          <SubText>Roadmap of<br />2024</SubText>
        </DownToUp>
        <Box>
          <DownToUp ref={timeLineRef}>
            <Timeline events={eventsData} />
          </DownToUp>
        </Box>
      </Container>
      {width > 768 ? <CenteredSVG viewBox="0 0 1440 709" preserveAspectRatio="none">
        <image href={background} width="100%" height="100%" />
      </CenteredSVG> : <div style={{position: 'absolute',left: '3em',top: '10em',opacity: '0.4'}}><div></div>
        <svg xmlns="http://www.w3.org/2000/svg" width="345" height="164" viewBox="0 0 345 164" fill="none">
          <path d="M441.685 162.34L441.685 64.9033C441.604 64.7952 441.494 64.6522 441.356 64.4762C441 64.0232 440.45 63.3515 439.686 62.4921C438.157 60.7733 435.77 58.3038 432.359 55.333C425.538 49.3919 414.618 41.4423 398.272 33.4823C365.582 17.5634 311.162 1.58964 224.385 1.58964C137.609 1.58964 81.7122 17.563 47.5434 33.4896C30.4578 41.4535 18.7983 49.4088 11.4222 55.3573C7.73389 58.3318 5.11579 60.8051 3.42527 62.5278C2.57999 63.3892 1.96656 64.063 1.56714 64.518C1.41751 64.6884 1.29792 64.8282 1.20743 64.9356L1.20743 162.34L441.685 162.34Z" fill="url(#paint0_linear_900_3573)" fill-opacity="0.5" stroke="url(#paint1_linear_900_3573)" stroke-width="2" />
          <defs>
            <linearGradient id="paint0_linear_900_3573" x1="15.7404" y1="117.436" x2="442.736" y2="120.984" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
            <linearGradient id="paint1_linear_900_3573" x1="15.7404" y1="117.436" x2="442.736" y2="120.984" gradientUnits="userSpaceOnUse">
              <stop stop-color="#101010" />
              <stop offset="0.3" stop-color="#6272C7" />
              <stop offset="0.7" stop-color="#DE98C4" />
              <stop offset="1" stop-color="#D7C0CA" />
            </linearGradient>
          </defs>
        </svg>
      </div>}

    </Section>
  );
};

export default RoadMap;

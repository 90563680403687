import React, { lazy, Suspense, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Loading from '../Loading';
import background from '../../assets/Page3/backgroundP3.svg';
import CardSwiperText from '../CardSwiperText.js';
import { DownToUp } from "../DownToUpFrames";

const CarouselTest = lazy(() => import("../CarouselTest"));

const Section = styled.section`
    min-height: 100vh;
    width: 100%;
    background-color: ${props => props.theme.body};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
`;

const Container = styled.div`
    width: 80%;
    margin: 0 auto;
    // display: flex;
    justify-content: center;
    align-items: stretch;

    @media (max-width: 70em){
        width: 85%;
    }

    @media (max-width: 64em){
        width: 100%;
        flex-direction: column;

        &>*:last-child{
            width: 80%;
        }
    }
    @media (max-width: 40em){
        &>*:last-child{
            width: 90%;
        }
    }
`;

const TextContainer = styled.div`
width: 100%;
margin: 0 auto;
display: column;
// flex-direction: column;
z-index: 999;


justify-content: center;
align-items: center;
@media (max-width: 550px){
    margin-left: 1.5em
}

`;

const SwiperTextContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    z-index: 999;
    justify-content: center;
    align-items: center;
`;

const Box = styled.div`
    width: 100%;
    //max-width: 720px;
    height: 100%;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease; 
    margin-top: 10%;

    @media (max-width: 40em){
        min-height: 50vh;
    }
`;

const SubText = styled.h1`
    font: 600 70px Poppins, sans-serif;
    color: ${(props) => props.theme.text};
    align-self: flex-start;
    width: 100%;
    margin: 0rem auto;
    // margin-left: 5.5%;

    .with {
        display: block;
        font-weight: 900;
        color: transparent;
        -webkit-text-stroke: 1px #fff;
    }

    @media (max-width: 64em) {
        margin-left: 0%;
        width: 100%;
        text-align: left;
        font-size: 90px;
    }

    @media (max-width: 40em) {
        margin-left: 0%;
        font-size: 50px;
        text-align: left;
    }

    @media (max-width: 30em) {
        margin-left: 0%;
        font-size: 35px;
        text-align: left;
    }
`;

const SubTitle = styled.h2`
    font: 600 90px Poppins, sans-serif;
    font-size: 28px;
    color: ${(props) => props.theme.text};
    margin-top: 60px; /* 设置与上方文本的间距 */
    z-index: 999;

    @media (max-width: 64em) {
        text-align: center;
    }
`;

const CenteredSVG = styled.svg`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 75%; /* 将SVG的顶部置于父容器的中间位置 */
  left: 50%; /* 将SVG的左侧置于父容器的中间位置 */
  transform: translate(-50%, -50%); 
  z-index: 1; 
`;

const Card = () => {

    let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    const titleRef = useRef(null);

    const checkScrollHeightAndLoadAnimation = () => {
        const windowHeight = window.innerHeight;
        const parentElement = document.getElementById("Card");
        const parentOffsetTop = parentElement.offsetTop;

        const titleElement = titleRef.current;
        if (window.pageYOffset + windowHeight > titleElement.offsetTop + parentOffsetTop + 200) {
            titleElement.classList.add('animate');
            titleElement.classList.remove('hide');
        } else {
            titleElement.classList.add('hide');
            titleElement.classList.remove('animate');
        }
    };

    useEffect(() => {
        checkScrollHeightAndLoadAnimation();
        window.addEventListener('scroll', checkScrollHeightAndLoadAnimation);
        return () => {
            window.removeEventListener('scroll', checkScrollHeightAndLoadAnimation);
        };
    }, []);
    return (
        <Section id="Card">
            <Container>
                <TextContainer>
                    <SubText>
                        <DownToUp ref={titleRef}>
                            Chai Market
                            <span className="with">Pros</span>
                        </DownToUp>

                        {/* <SubTitle>
            Seamless Interoperability
            </SubTitle> */}
                    </SubText>
                    <SwiperTextContainer>
                    </SwiperTextContainer>
                </TextContainer>

                <Box>
                    <Suspense fallback={<Loading />}>
                        <CarouselTest /> </Suspense> </Box>

            </Container>
            {width > 768 ? <CenteredSVG>
                <image href={background} />
            </CenteredSVG> : <div style={{position: 'absolute',left: 0,top: '31em',opacity: '0.5'}}><svg xmlns="http://www.w3.org/2000/svg" width="169" height="161" viewBox="0 0 169 161" fill="none">
                <mask id="mask0_900_3512" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="-3" y="0" width="172" height="161">
                    <rect width="171.716" height="161" transform="matrix(-1 -1.74846e-07 -1.74846e-07 1 169 0)" fill="url(#paint0_linear_900_3512)" />
                </mask>
                <g mask="url(#mask0_900_3512)">
                    <path d="M132.02 125.438L163.531 125.438C163.531 125.438 183.947 146.02 183.947 226.408C183.947 306.795 163.531 330.096 163.531 330.096L132.02 330.096L132.02 125.438Z" fill="url(#paint1_linear_900_3512)" />
                    <path d="M96.5127 98.25L128.024 98.25C128.024 98.25 148.44 118.832 148.44 199.22C148.44 279.608 128.024 302.909 128.024 302.909L96.5127 302.909L96.5127 98.25Z" fill="url(#paint2_linear_900_3512)" />
                    <path d="M61.0078 55.5332L92.5194 55.5332C92.5194 55.5332 112.935 76.1156 112.935 156.503C112.935 236.891 92.5194 260.192 92.5194 260.192L61.0078 260.192L61.0078 55.5332Z" fill="url(#paint3_linear_900_3512)" />
                    <path d="M25.501 28.3496L57.0126 28.3496C57.0126 28.3496 77.4285 48.932 77.4285 129.32C77.4285 209.708 57.0125 233.008 57.0125 233.008L25.501 233.008L25.501 28.3496Z" fill="url(#paint4_linear_900_3512)" />
                    <path d="M-6.89746 0L24.6141 2.75483e-06C24.6141 2.75483e-06 45.0301 20.5824 45.0301 100.97C45.03 181.358 24.6141 204.659 24.6141 204.659L-6.89748 204.659L-6.89746 0Z" fill="url(#paint5_linear_900_3512)" />
                    <path d="M-37.9648 -27.1836L-6.45327 -27.1836C-6.45327 -27.1836 13.9627 -6.60121 13.9627 73.7866C13.9627 154.174 -6.45329 177.475 -6.45329 177.475L-37.9649 177.475L-37.9648 -27.1836Z" fill="url(#paint6_linear_900_3512)" />
                </g>
                <defs>
                    <linearGradient id="paint0_linear_900_3512" x1="85.8579" y1="0" x2="85.8579" y2="161" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A2A2A2" stop-opacity="0" />
                        <stop offset="0.5" stop-color="#D9D9D9" />
                        <stop offset="1" stop-color="#737373" stop-opacity="0" />
                    </linearGradient>
                    <linearGradient id="paint1_linear_900_3512" x1="147.775" y1="125.438" x2="147.775" y2="330.096" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                    <linearGradient id="paint2_linear_900_3512" x1="112.268" y1="98.25" x2="112.268" y2="302.909" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                    <linearGradient id="paint3_linear_900_3512" x1="76.7636" y1="55.5332" x2="76.7636" y2="260.192" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                    <linearGradient id="paint4_linear_900_3512" x1="41.2568" y1="28.3496" x2="41.2567" y2="233.008" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                    <linearGradient id="paint5_linear_900_3512" x1="8.85833" y1="1.37742e-06" x2="8.85831" y2="204.659" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                    <linearGradient id="paint6_linear_900_3512" x1="-22.2091" y1="-27.1836" x2="-22.2091" y2="177.475" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#101010" />
                        <stop offset="0.3" stop-color="#6272C7" />
                        <stop offset="0.7" stop-color="#DE98C4" />
                        <stop offset="1" stop-color="#D7C0CA" />
                    </linearGradient>
                </defs>
            </svg></div>}

        </Section>
    )
}

export default Card;
import React from 'react';
import styled from 'styled-components';


const IconContainer = styled.div`
display: flex;
position: relative;
align-items: center;
justify-content: center;
width: 2rem;
height: 2rem;
border-radius: 50%;
background: rgba(255, 227, 255, 0.20);

    &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); /* 白色半透明背景 */
        border-radius: 50%; /* 圆角效果 */
    }
`;


const IconDoc = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
<path d="M13.3066 3.14258C13.3915 3.14275 13.4752 3.16294 13.5509 3.20151L13.5744 3.21436C13.599 3.22876 13.6225 3.24487 13.6448 3.26258L13.6537 3.27008L13.6784 3.29258L17.2641 6.87827L17.2751 6.89005L17.278 6.89327L17.2566 6.87077L17.2862 6.90291L17.298 6.9172C17.3091 6.93121 17.3196 6.94575 17.3294 6.96077L17.3419 6.98184L17.3548 7.00505C17.3926 7.07862 17.4137 7.16148 17.4137 7.24969V18.1425C17.4137 18.3319 17.3384 18.5136 17.2045 18.6475C17.0705 18.7815 16.8889 18.8568 16.6994 18.8568H5.27092C5.08148 18.8568 4.8998 18.7815 4.76585 18.6475C4.6319 18.5136 4.55664 18.3319 4.55664 18.1425V3.85686C4.55664 3.66742 4.6319 3.48574 4.76585 3.35179C4.8998 3.21783 5.08148 3.14258 5.27092 3.14258H13.3066ZM12.0566 4.214H5.62806V17.7853H16.3423V8.49968H12.7709C12.5814 8.49968 12.3998 8.42443 12.2658 8.29048C12.1318 8.15652 12.0566 7.97484 12.0566 7.7854V4.214ZM11.5209 13.4997C11.663 13.4997 11.7992 13.5561 11.8997 13.6566C12.0001 13.757 12.0566 13.8933 12.0566 14.0354C12.0566 14.1774 12.0001 14.3137 11.8997 14.4142C11.7992 14.5146 11.663 14.5711 11.5209 14.5711H8.66376C8.52168 14.5711 8.38542 14.5146 8.28495 14.4142C8.18449 14.3137 8.12805 14.1774 8.12805 14.0354C8.12805 13.8933 8.18449 13.757 8.28495 13.6566C8.38542 13.5561 8.52168 13.4997 8.66376 13.4997H11.5209ZM13.3662 10.2854C13.6291 10.2854 13.8423 10.5254 13.8423 10.8211C13.8423 11.1168 13.6291 11.3568 13.3662 11.3568H8.60411C8.34126 11.3568 8.12805 11.1168 8.12805 10.8211C8.12805 10.5254 8.34126 10.2854 8.60411 10.2854H13.3662ZM13.128 4.25721V7.42826H16.2991L13.128 4.25721Z" fill="white" stroke="white" stroke-width="0.392854"/>
</svg>
);


const HoverableIconDoc = () => (
  <IconContainer>
      <IconDoc />
  </IconContainer>
);

export default HoverableIconDoc;

import React from 'react';
import styled from 'styled-components';

const IconContainer = styled.div`
    display: inline-block;
    position: relative;
    width: 44px;
    height: 44px;

    &:hover::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4); /* 白色半透明背景 */
        border-radius: 50%; /* 圆角效果 */
    }
`;

const IconX = () => (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.00012207" width="43.9997" height="43.9997" rx="21.9998" fill="#FFE3FF" fillOpacity="0.2"/>
        <path d="M26.5182 14.8505H28.9278L23.6636 20.8672L29.8566 29.0546H25.0075L21.2095 24.089L16.8638 29.0546H14.4527L20.0834 22.6191L14.1424 14.8505H19.1146L22.5476 19.3892L26.5182 14.8505ZM25.6725 27.6124H27.0077L18.3891 16.217H16.9563L25.6725 27.6124Z" fill="white"/>
    </svg>
);

const HoverableIconX = () => (
    <IconContainer>
        <IconX />
    </IconContainer>
);

export default HoverableIconX;

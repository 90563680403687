import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import Button from './Button';
import Logo from './Logo';
import IconX from '../Icons/IconX';
import ButtonText from '../Icons/ButtonText';

import IconDoc from '../Icons/IconDoc';

// 定义渐显和向上移入的keyframes动画
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Section = styled.section`
  width: 100vw;
  background-color: ${props => props.theme.body};
`;


const Menu = styled.ul`
display: flex;
justify-content: space-between;
align-items: center;
list-style:none;


@media (max-width: 550px) {
/* 1024 px */



position: fixed;
top: ${props => props.theme.navHeight};
left: 0;
right: 0;
bottom: 0;
width: 100vw;
height: ${props => `calc(100vh - ${props.theme.navHeight})`};
z-index:50;
background-color: ${props => `rgba(${props.theme.bodyRgba},0.85)`};
backdrop-filter: blur(2px);

transform: ${props => props.click ? 'translateY(0)' : `translateY(1000%)`};
transition: all 0.3s ease;
flex-direction: column;
justify-content: center;

touch-action: none;


}

`

const NavBar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  z-index: 1000;
  border: 1px solid rgba(255, 227, 255, 0.05);
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  background-color: ${props => props.isScrolled ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  backdrop-filter: ${props => props.isScrolled ? 'blur(10px)' : 'none'};

  .mobile {
    display: none;
  }

  @media (max-width: 550px) {
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
    }
  }
`;

const IconList = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  margin-right: 80px;

  & > * {
    padding-left: 20px;
    transition: all 0.2s ease;
    animation: ${fadeInUp} 1s ease forwards;
    opacity: 0;

    &:hover {
      transform: scale(1.2);
    }
  }
`;

const MenuItem = styled.li`
  margin: 0 1rem;
  color: ${props => props.theme.text};
  cursor: pointer;

  &::after {
    content: ' ';
    display: block;
    width: 0%;
    height: 2px;
    background: ${props => props.theme.text};
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100%;
  }

  @media (max-width: 550px) {
    margin: 1rem 0;

    &::after {
      display: none;
    }
  }
`;

const HamburgerMenu = styled.span`
  width: ${props => (props.click ? '2rem' : '1.5rem')};
  height: 2px;
  background: ${props => props.theme.text};
  position: absolute;
  top: 2rem;
  right: 2rem;
  transform: ${props => (props.click ? 'translateX(-50%) rotate(90deg)' : 'translateX(-50%) rotate(0)')};
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;

  @media (max-width: 550px) {
    display: flex;
  }

  &::after,
  &::before {
    content: ' ';
    width: ${props => (props.click ? '1rem' : '1.5rem')};
    height: 2px;
    right: ${props => (props.click ? '-2px' : '0')};
    background: ${props => props.theme.text};
    position: absolute;
    transition: all 0.3s ease;
  }

  &::after {
    top: ${props => (props.click ? '0.3rem' : '0.5rem')};
    transform: ${props => (props.click ? 'rotate(-40deg)' : 'rotate(0)')};
  }

  &::before {
    bottom: ${props => (props.click ? '0.3rem' : '0.5rem')};
    transform: ${props => (props.click ? 'rotate(40deg)' : 'rotate(0)')};
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 550px) {
    display: none;
  }
`;

const LogoWrapper = styled.div`
  animation: ${fadeInUp} 1s ease forwards;
`;

const AnimatedButton = styled(Button)`
  animation: ${fadeInUp} 1s ease forwards;
  opacity: 0;

  &:hover {
    transform: scale(1.2);
  }
`;

const Navigation = () => {
  const [click, setClick] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollTo = id => {
    let element = document.getElementById(id);

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    setClick(!click);
  };

  return (
    <Section id="navigation">
      <NavBar isScrolled={isScrolled}>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <HamburgerMenu click={click} onClick={() => setClick(!click)}>
          &nbsp;
        </HamburgerMenu>


        {/*<Menu click={click}>*/}

        {/*  <MenuItem>*/}
        {/*    <div className="mobile">*/}
        {/*      <Button svg={ButtonText} link="https://google.com" />*/}
        {/*    </div>*/}
        {/*  </MenuItem>*/}
        {/*</Menu>*/}
        <NavItems className="desktop">
          <Button svg={ButtonText}
                  // link="#"
          />

          {/* <Button text="Launch App" link="https://google.com" /> */}
          <IconList>
            <a
              href="https://x.com/Chai_Market"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="IconX">
              <IconX />
            </a>
            <a
              // href="#"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="IconDoc">
              <IconDoc />
            </a>
          </IconList>

        </NavItems>
      </NavBar>
    </Section>
  );
};

export default Navigation;

import * as React from "react";
import styled from "styled-components";  // 确保导入 styled-components

const Svg = styled.svg`
  fill: ${props => props.theme.text};  // 使用主题颜色设置填充颜色
`;

const X = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.0693 20.5783L19.1166 11.9151L19.0719 11.85H18.993H12H11.7149L11.8764 12.0849L20.9793 25.333L11.8863 35.9022L11.6731 36.15H12H14.0719H14.1407L14.1856 36.0978L22.0749 26.9273L28.3674 36.0849L28.4121 36.15H28.491H35.484H35.7691L35.6076 35.9151L26.1653 22.1728L34.8323 12.0978L35.0455 11.85H34.7186H32.6467H32.5779L32.533 11.9022L25.0693 20.5783ZM23.2616 25.3747L23.2616 25.3747L22.3325 24.0461L22.3325 24.046L15.1062 13.7096H17.9224L23.8422 22.1779L23.8422 22.1779L24.7711 23.5066L32.3598 34.3613H29.544L23.2616 25.3747Z" fill="white" stroke="white" strokeWidth="0.3"/>
</svg>

  
);

export default X;
